import { defineStore } from 'pinia'
import {useCookieStore} from "~/store/cookie";

export const DEFAULT_COLORS = [
  '#000000', '#FFFFFF', '#FF1900', '#F47365',
  '#FFB243', '#FFE623', '#6EFF2A', '#1BC7B1',
  '#00BEFF', '#2E81FF', '#5D61FF', '#FF89CF',
  '#FC3CAD', '#BF3DCE', '#8E00A7', 'rgba(0,0,0,0)'
];

export const useAssetStore = defineStore({
  id: 'asset-store',
  state: () => {
    return {
      font: null,
      fetchError: false,
      uploadData: null,
      errorMessage: null,
      fontPage: null,
      currentPage: 1,
      uploadPage: null,
      tooLargeError: false,
      invalidFontError: false,
      deleteError: false,
      colors: [],
    }
  },
  getters: {
    userColors: (state) => {
      const {t} = useI18n();
      let userColors = [];
      DEFAULT_COLORS.forEach((color, idx) => {
        const c = state.colors.find(c => c.number === idx + 1)
        if (c) {
          userColors.push(c);
        }
        else {
          userColors.push({color:color, number: idx + 1, id: null, name: t('color.default.' + (idx + 1)) });
        }
      });

      return userColors;
    },
    userColorArray: (state) => {
      const {t} = useI18n();
      let userColors = [];
      DEFAULT_COLORS.forEach((color, idx) => {
        const c = state.colors.find(c => c.number === idx + 1)
        if (c) {
          userColors.push(c.color);
        }
        else {
          userColors.push(color);
        }
      });

      return userColors;
    }
  },
  actions: {
    async uploadFont(fontFile, fontFileName, config) {
      let formData = new FormData();
      formData.append('name', fontFileName);
      formData.append('files', fontFile);

      const cookieStore = useCookieStore();
      const resp = await $fetch(config.public.externalApiBase + `/api/upload/`, {
        method: 'PUT',
        server: false,
        body: formData,
        params: {
          upload_type: 'Font'
        },
        headers: {'Authorization': 'Token ' + cookieStore.token}
      }).catch((error) => {
        this.fetchError = true;

        if (! error.response) {
          this.errorMessage = 'error.something.went.wrong';
        }
        else if (error.response.status === 415) {
          this.errorMessage = 'unsupported.file.type.font';
        }
        else if (error.response.status === 422) {
          this.errorMessage = 'invalid.font.file';
        }
        else if (error.response.status === 423) {
          this.errorMessage = 'color.font.file';
        }
        else if (error.response.status === 409) {
          this.errorMessage = 'font.already.exists';
        }
        else if (error.response._data && error.response._data.error ) {
          this.errorMessage = error.response._data.error;
        }
      })

      this.uploadData = resp;
    },
    async fetchfonts(page, config) {
      const cookieStore = useCookieStore();

      const resp = await $fetch(config.public.externalApiBase + `/api/fonts/`, {
        headers: {'Authorization': 'Token ' + cookieStore.token},
        params: {
          page: page,
          mine: true,
          upload_type: 'font'
        }
      }).catch((error) => {
        this.fetchError = true;
      });

      this.fontPage = resp;
    },
    async deleteFont(fontId, config) {
      const cookieStore = useCookieStore();
      this.deleteError = false;
      await $fetch(config.public.externalApiBase + `/api/fonts/${fontId}/`, {
        method: 'DELETE',
        headers: {'Authorization': 'Token ' + cookieStore.token},
      }).catch((error) => {
        this.deleteError = true;
      });
    },
    async deleteFileUpload(key, config) {
      const cookieStore = useCookieStore();
      this.deleteError = false;
      await $fetch(config.public.externalApiBase + `/api/uploads/${key}/`, {
        method: 'DELETE',
        headers: {'Authorization': 'Token ' + cookieStore.token},
      }).catch((error) => {
        this.deleteError = true;
      });
    },
    async fetchUploads(page, upload_type, config) {
      const cookieStore = useCookieStore();

      const resp = await $fetch(config.public.externalApiBase + `/api/uploads/`, {
        headers: {'Authorization': 'Token ' + cookieStore.token},
        params: {
          page: page,
          upload_type: upload_type
        }
      }).catch((error) => {
        this.fetchError = true;
      })

      this.uploadPage = resp;
    },
    async fetchUserColors(config) {
      const cookieStore = useCookieStore();
      const resp = await $fetch(config.public.externalApiBase + `/api/colors/`, {
        headers: {'Authorization': 'Token ' + cookieStore.token},

      }).catch((error) => {
            this.fetchError = true;
          }
      );
      if (resp && resp.results){
        this.colors = resp.results;
      }
    },
    async createUserColor(color, config) {
      const cookieStore = useCookieStore();
      const resp = await $fetch(config.public.externalApiBase + `/api/colors/`, {
        method: 'POST',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        body: {
          color: color.color,
          name: color.name,
          number: color.number
        }
      }).catch((error) => {
            this.fetchError = true;
          }
      );
    },
    async updateUserColor(colorId, color, config) {
      const cookieStore = useCookieStore();
      const resp = await $fetch(config.public.externalApiBase + `/api/colors/${colorId}/`, {
        method: 'PUT',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        body: {
          color: color.color,
          name: color.name,
          number: color.number
        }
      }).catch((error) => {
          this.fetchError = true;
        }
      );
    }
  }
});